import './App.css'
import { useState } from 'react';
import { User, Response } from './ChatWindow'
import { deleteChat } from './Lambda';

function Entry({ conversation, openChat }) {
  return (
    <div className="entry" onClick={() => openChat(conversation)} >
      {conversation.savename}
    </div>
  )
}

function PastBlock({ loading, data, error, username, usersub }) {

  const [modalOpen, setModalOpen] = useState(false);
  const [pastConvo, setPastConvo] = useState(null);

  const deleteConversation = (conversation) => {
    deleteChat(usersub, conversation.savename, (success) => console.log(`Conversation deleted response: ${success}`))
    setModalOpen(false);
    setPastConvo(null);
  }

  const openConversation = (conversation) => {
    if(pastConvo === conversation) {
      setModalOpen(false);
      setPastConvo(null);
    } else {
      setModalOpen(true);
      setPastConvo(conversation);
    }
  }

  return (
    <div className="past-block">
      {
        loading ? <div className="past-loading">Loading...</div> :
        error ? <div className="past-error">Problem loading data. Please let the developers know.</div> :
        data.map(entry => <Entry conversation={entry} openChat={openConversation} />)
      }
      {modalOpen && (
        <div className='past-modal'>
          <div className="past-conversation"> 
            <div className="past-close" onClick={ () => { setModalOpen(false); setPastConvo(null); }  }>&times;</div>
            <div className="past-conversation-title">{pastConvo.savename}</div>  
            <div className='past-delete'>
              <button className='past-delete-button' onClick={() => deleteConversation(pastConvo)} >Delete conversation</button>
            </div>
            <div className="past-conversation-content">
              <div className="chat">
                {pastConvo.savedata.slice().reverse().map((entry, index) => (
                  entry.who === "BrewBuddy" ? 
                  <Response key={index} who={entry.who} text={entry.message} /> : 
                  <User key={index} who={username} text={entry.message} img={entry.img}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ButtonBlock({ signOut }) {
  return (
    <div className="button-block">
      <div className="button-sector">
        <button onClick={() => signOut()}>Sign Out</button>
      </div>
    </div>
  );
}

function SideBar({ signOut, loading, data, error, username, usersub }) {
  return (
    <div className="side-bar">
      <PastBlock loading={loading} data={data} error={error} username={username} usersub={usersub} />
      <ButtonBlock signOut={signOut}/>
    </div>
  );
}

export default SideBar;