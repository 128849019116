import React from 'react';

function MDFormatting(props) {
  const formatText = (text) => {
    text = text.replace(/\*\*(.*?)\*\*/g, (_, boldText) => `<strong>${boldText}</strong>`);
    text = text.replace(/\*(.*?)\*/g, (_, italicText) => `<em>${italicText}</em>`);
    text = text.replace(/`(.*?)`/g, (_, codeText) => `<code>${codeText}</code>`);
    return text;
  };

  const textWithBreaks = props.text.split('\n').map((text, index) => {
    let content = text;
    const noMarginStyle = { margin: '0', marginBottom: '8px' };

    if (content.startsWith('# ')) {
      content = <h1 key={index} style={noMarginStyle} dangerouslySetInnerHTML={{ __html: formatText(content.substring(2)) }} />;
    } else if (content.startsWith('## ')) {
      content = <h2 key={index} style={noMarginStyle} dangerouslySetInnerHTML={{ __html: formatText(content.substring(3)) }} />;
    } else if (content.startsWith('### ')) {
      content = <h3 key={index} style={noMarginStyle} dangerouslySetInnerHTML={{ __html: formatText(content.substring(4)) }} />;
    } else if (content.startsWith('#### ')) {
      content = <h3 key={index} style={noMarginStyle} dangerouslySetInnerHTML={{ __html: formatText(content.substring(4)) }} />;
    } else if (content.startsWith('- ')) {
      content = <li key={index} dangerouslySetInnerHTML={{ __html: formatText(content.substring(2)) }} />;
    } else {
      content = <span key={index} style={noMarginStyle} dangerouslySetInnerHTML={{ __html: formatText(content) }} />;
    }

    return (
      <React.Fragment key={index}>
        {content}
        {text.startsWith('- ') ? null : text.startsWith('#') ? null : <br />}
      </React.Fragment>
    );
  });

  return (
    <div>
        {textWithBreaks}
    </div>
  );
}

export default MDFormatting;