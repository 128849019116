import { getCurrentUser } from "@aws-amplify/auth";

export function messageAPICall(message, threadID, usersub, img, callback) {

  getCurrentUser().then((username, userId, signInDetails) => {
    console.log(username)
    console.log(usersub)
    fetch(`https://vve0koauif.execute-api.ap-southeast-2.amazonaws.com/indev-chat-handler`, {
      method: 'POST',
      body: JSON.stringify({
        "message": message,
        "threadID": threadID,
        "sub": usersub,
        "photo": img
      })
    }).then(
      response => {
        response.text().then(text => {
          const json_read = JSON.parse(text)
  
          if (typeof json_read.threadID === 'undefined') {
            callback(json_read.message, "error")
          }
  
          else {
            callback(json_read.message, json_read.threadID)
          }
        })
      }
    )
  })
}

export function uploadImage(file, userSub, callback) {
  const reader = new FileReader();
  
  reader.onloadend = () => {
    try {
      const base64img = reader.result.split(",")[1];
      const contentType = file.type;

      fetch("https://vve0koauif.execute-api.ap-southeast-2.amazonaws.com/alphaimages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          sub: userSub,
          image_data: base64img,
          content_type: contentType
        })
      })
      .then(response => response.json())
      .then(data => {
         console.log(data)
         callback(data.public_url);
      })
      .catch(error => {
         console.error("Error uploading image: ", error);
         callback("error");
      });
    } catch (error) {
      console.error("Error uploading image: ", error);
      callback("error");
    }
  };

  reader.readAsDataURL(file);
}

export function endChat(usersub, save, savename, conversation, callback) {

  fetch("https://vve0koauif.execute-api.ap-southeast-2.amazonaws.com/alphaendconvo", {
    method: 'POST',
    body: JSON.stringify({
      "sub": usersub,
      "conversation": conversation,
      "save": save,
      "savename": savename
    })
  }).then((response) => {
    if (response.status === 200) {
      callback(true);
    } else {
      callback(false);
    }
  }).catch((reason) => {
    console.log("End conversation failure reason: ", reason);
    callback(false);
  })
}

export function deleteChat(usersub, savename, callback) {

  fetch("https://vve0koauif.execute-api.ap-southeast-2.amazonaws.com/alphadeleteconvo", {
    method: 'POST',
    body: JSON.stringify({
      "sub": usersub,
      "savename": savename
    })
  }).then((response) => {
    if (response.status === 200) {
      callback(true);
    } else {
      console.log(response);
      callback(false);
    }
  }).catch((reason) => {
    console.log("Delete conversation failure reason: ", reason);
    callback(false);
  })
}