import React, { useState } from "react";
import { uploadImage } from "./Lambda";

const ImageUploader = ({ userSub, uploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSuccess = (publicUrl) => {
    if (publicUrl === "error") {
      alert("Error uploading image.");
    } else {
      setImageUrl(publicUrl);
      uploadSuccess(publicUrl);
      console.log("Image uploaded successfully!", publicUrl);
    }
  }
const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }
    
    uploadImage(selectedFile, userSub, handleSuccess);
  };

  return (
  <div className="image-upload-portion">
      {imageUrl ? (
        <div className={"image-modal-uploaded"}>
          <img src={imageUrl} alt="Uploaded" />
        </div>
      ): (
        <div className={"image-modal-upload-view"}>
          <button onClick={handleUpload}>Upload</button>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;