import './App.css';
import { Context } from "./App"
import { useState, useContext } from 'react';

import { messageAPICall, endChat } from './Lambda';
import './Formatting.js'
import MDFormatting from './Formatting.js';

import ImageUploader from './ImageUploader.js';

function ImageModal ({ closeModal, userSub, sendImagePrompt }) {
  const [imageUrl, setImageUrl] = useState("none")
  const [imagePrompt, setImagePrompt] = useState("")

  const uploaded = (url) => {
    setImageUrl(url);
  }

  const sendPrompt = () => {
    sendImagePrompt(imageUrl, imagePrompt);
    closeModal();
  }

  return (
    <div className="image-modal">
      <div className="image-modal-content">
        <span className="image-cancel" onClick={closeModal}>&times;</span>
        <ImageUploader userSub={userSub} uploadSuccess={uploaded}/>
        <div className="image-prompt-area">
          <textarea
            placeholder='Enter prompt'
            value={imagePrompt}
            onChange={(e) => setImagePrompt(e.target.value)}
           />
          <button disabled={imageUrl === "none"} onClick={sendPrompt}>Send</button>
        </div>
      </div>
    </div>
  );
}

function EndModal ({ cancel, save, end }) {
  const [saveName, setSaveName] = useState("")

  const triggerSave = () => {
    save(saveName);
  }

  return (
    <div className="endc-modal">
      <div className="endc-modal-content">
        <span className="endc-cancel" onClick={cancel}>&times;</span>
        <div className="end-message">Save Conversation?</div>
        <div className="endc-prompt-area">
          <textarea
            value={saveName}
            placeholder='Enter a conversation name.'
            onChange={(e) => setSaveName(e.target.value)}
           />
           <div className="endc-buttonrack">
            <button onClick={triggerSave}>Save</button>
            <button onClick={end}>Delete</button>
            <button onClick={cancel}>Cancel</button>
           </div>
        </div>
      </div>
    </div>
  );
}

function ChatInput({ chat, setChat, user }) {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageSelect, setImageSelect] = useState(false);
  const [endModal, setEndModal] = useState(false);

  const openImageModal = () => setImageSelect(true);
  const closeModal = () => setImageSelect(false);
  
  const addMessage = (message) => {
    const newMessage = {
      who: "User",
      message: message,
      img: "none"
    };

    setChat({...chat, "conversation": [...chat.conversation, newMessage]});
    setLoading(true);

    messageAPICall(message, chat.threadID, user.userId, "none", (response, currentID) => {
      const reply = {
        who: "BrewBuddy",
        message: response
      };

      setChat({...chat, "threadID": currentID, "conversation": [...chat.conversation, newMessage, reply]})
      setLoading(false);
    });
  };

  const sendImage = (url, message) => {
    const newMessage = {
      who: "User",
      message: message,
      img: url
    };

    setChat({...chat, "conversation": [...chat.conversation, newMessage]});
    setLoading(true);

    messageAPICall(message, chat.threadID, user.userId, url, (response, currentID) => {
      const reply = {
        who: "BrewBuddy",
        message: response
      };

      setChat({...chat, "threadID": currentID, "conversation": [...chat.conversation, newMessage, reply]})
      setLoading(false);
    });
  }

  const handleSend = () => {
    if (input.trim() !== "") {
      addMessage(input);
      setInput("");
    }
  }

  const endConversation = (savename) => {
    setLoading(true);
    setEndModal(false);
    endChat(user.userId, "true", savename, chat.conversation, (success) => {
      if (!success) {
        console.log("Failed...")
      } else {
        setLoading(false);
        setChat({"threadID": "new", "conversation": []});
      }
    })
  }

  const deleteConversation = () => {
    setLoading(true);
    setEndModal(false);
    endChat(user.userId, "false", "none", chat.conversation, (success) => {
      if (!success) {
        setLoading(false);
        console.log("Failed...")
      } else {
        setLoading(false);
        setChat({"threadID": "new", "conversation": []});
      }
    })
  }

  return (
    <div className="chat-input">
      { loading ? (<div className="spinwheel"><i class="fa fa-spinner"></i></div>) : false }
      <div className="add-photo" onClick={openImageModal}><i class="fa fa-image"></i></div>
      <div className="end-conversation" onClick={() => setEndModal(true)}><i className="fa fa-times" /></div>
      <textarea
        value={input}
        placeholder='Enter prompt'
        onChange={(e) => setInput(e.target.value)}
      />
      <div className="button-rack">
        <button onClick={handleSend}>Send</button>
      </div>

      {imageSelect && <ImageModal closeModal={closeModal} userSub={user.userId} sendImagePrompt={sendImage} />}
      {endModal && <EndModal cancel={() => { setEndModal(false); setLoading(false); } } save={endConversation} end={deleteConversation} />}
    </div>
  );
}

export function Response({ who, text }) {
  return (
    <div className="response">
      <div className="who">{who}</div>
      <div className="text"><MDFormatting text={text}/></div>
    </div>
  );
}

export function User({ who, text, img }) {
  return (
    <div className="user">
      <div className="who">{who}</div>
      {img === "none"? null : <img src={img} alt={"Uploaded image."} className="user-message-img" />}
      <div className="text"><MDFormatting text={text}/></div>
    </div>
  );
}

function Chat({ chat, setChat, username }) {
  if(chat.threadID === "none")
    return (
      <div className="chat">
        <div className="no-content">
          No Conversation
        </div>
      </div>
    );

  return (
    <div className="chat">
      {chat.conversation.slice().reverse().map((entry, index) => (
        entry.who === "BrewBuddy" ? 
        <Response key={index} who={entry.who} text={entry.message} /> : 
        <User key={index} who={username} text={entry.message} img={entry.img}/>
      ))}
    </div>
  );
}

function ChatWindow({ username, user }) {
  const { chat, setChat } = useContext(Context);

  return (
    <div className="chat-window">
      <Chat chat={chat} setChat={setChat} username={username} />
      <ChatInput chat={chat} setChat={setChat} user={user} />
    </div>
  )
}

export default ChatWindow;