import { Amplify } from 'aws-amplify';
import './App.css';
import ChatWindow from './ChatWindow';
import SideBar from './Sidebar';
import React from 'react';
import { useState, useEffect, createContext } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: 'ap-southeast-2_CIFUwCbQ5',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: '739g7gmseje467jmoopsebdbdj'
    }
  }
});

// Create a context
export const Context = createContext();

// Create a provider component
function Provider({ children }) {
  const [chat, setChat] = useState({"threadID": "new", "conversation": []});

  return (
    <Context.Provider value={{ chat, setChat }}>
      {children}
    </Context.Provider>
  );
}

function Content({ signOut, user }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toggled, setToggled] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // Function to make API call
    const fetchData = () => {
      fetch('https://vve0koauif.execute-api.ap-southeast-2.amazonaws.com/alphagetconvos', {
        method: 'POST',
        body: JSON.stringify({
          "sub": user.userId
        })
      })
        .then(response => {
          if (!response.ok) { // Check if the response is not ok
            throw new Error(`Error: ${response.statusText}`);
          }
          return response.json();
        })
        .then(result => {
          setData(result);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    // Call the fetchData function
    fetchData();
  }, []); // Empty dependency array

  const toggleView = () => {
    if (toggled) {
      document.documentElement.style.setProperty('--view', "chat");
      setToggled(false)
    } else {
      document.documentElement.style.setProperty('--view', "sidebar");
      setToggled(true)
    }
  }

  useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const isMobile = windowWidth <= 770;

  return (
    <div>
      {isMobile ? (
        <Provider>
          <div className="App">
          <div className="toggle" onClick={toggleView}><i className="fa fa-solid fa-bars" /></div>
            <div className="Main-Grid">
              {!toggled ? (
                <ChatWindow username={user.username} user={user} />
              ) : (
                <SideBar signOut={signOut} loading={loading} data={data} error={error} username={user.username} usersub={user.userId} />
              )}
            </div>
          </div>
        </Provider>
      ) : (
        <Provider>
          <div className="App">
            <div className="Main-Grid">
              <SideBar signOut={signOut} loading={loading} data={data} error={error} username={user.username} usersub={user.userId} />
              <ChatWindow username={user.username} user={user} />
            </div>
          </div>
        </Provider>
      )}
    </div>
);
}


function App({ signOut, user }) {
  return (
    <Content signOut={signOut} user={user} />
  );
}

export default withAuthenticator(App, {includeGreetings: true});